<template>
  <div v-if="showSessionClass">
    <Heading heading="h4" :content="myTitle"></Heading>
    <hr />
    <div class="ml-5">
      <el-form
        :model="sessionClass"
        ref="newTest"
        label-width="140px"
        @submit.native.prevent
        @keyup.enter.native="createNew('newTest')"
      >
        <!-- <el-form-item :label="$t('TeacherCourses.Teacher')" required>
          <el-table
            v-if="teacher_users.length > 0"
            border
            :data="teacher_users"
            class="mb-3"
          >
            <el-table-column :label="$t('TeacherCourses.Teacher')" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Register Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Action')" width="100px">
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  :content="$t('TeacherCourses.Remove Teacher')"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="small"
                    @click="removeTeacher(scope.$index)"
                  >
                    <i class="fas fa-user-minus"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item> -->
        <el-form-item :label="$t('TeacherCourses.Students')" prop="students">
          <el-table border :data="student_users" class="mb-3">
            <el-table-column :label="$t('TeacherCourses.Students')" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Action')" width="100px">
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  :content="$t('TeacherCourses.Remove Student')"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="small"
                    @click="removeStudent(scope.$index)"
                  >
                    <i class="fas fa-user-minus"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      append-to-body
      :title="$t('TeacherCourses.Add Student')"
      :visible.sync="showStudents"
      width="50%"
    >
      <el-form>
        <el-form-item>
          <el-select
            style="width: 100%;"
            v-model="selectedStudent"
            popper-class="select-popper"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            placeholder="Email / Name"
            :remote-method="searchUsers"
          >
            <el-option
              v-for="(student, index) in students"
              :key="student.id"
              :label="
                `${user.displayName(student.first_name, student.last_name)} [${
                  student.email
                }]`
              "
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showStudents = false">Cancel</el-button>
        <el-button type="success" @click="addStudent">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog
      append-to-body
      :title="$t('TeacherCourses.Add Teacher')"
      :visible.sync="showTeachers"
      width="50%"
    >
      <el-form>
        <el-form-item>
          <el-select
            style="width: 100%;"
            v-model="selectedTeacher"
            popper-class="select-popper"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            placeholder="Email / Name"
            :remote-method="searchTeachers"
          >
            <el-option
              v-for="(teacher, index) in teachers"
              :key="teacher.id"
              :label="
                `${user.displayName(teacher.first_name, teacher.last_name)} [${
                  teacher.email
                }]`
              "
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTeachers = false">Cancel</el-button>
        <el-button type="success" @click="addTeacher">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Class from "@/views/courses/apis/Add.js";
import SAT from "@/apis/sat";
import Common from "@/mixins/common.js";
import Theme from "@/common/theme";
import role from "@/mixins/role.js";
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      pickerOptions: {
        disabledDate(date) {
          return date.getTime() < Date.now();
        }
      },
      types: ["Modules", "Drills"],
      fullTypes: ["Linear", "Adaptive"],
      showSessionClass: true,
      classDate: [],
      sessionClass: {
        name: "",
        description: "",
        // is_online: 0,
        started_at: "",
        ended_at: "",
        started_time: "",
        ended_time: ""
      },
      subjects: [],
      showStudents: false,
      students: [],
      student_users: [],
      old_student_users: [],
      selectedStudent: null,
      showTeachers: false,
      teachers: [],
      teacher_users: [],
      old_teacher_users: [],
      selectedTeacher: null,
      composePractices: null,
      adaptivePractices: null,
      modules: null,
      drills: null,
      practices: null,
      DrillPractices: null,
      classExams: []
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isAdmin() {
      return (
        this.$route.name === "AdminSatAddCourse" ||
        this.$route.name === "AdminEditCourse"
      );
    },
    isTeacher() {
      return (
        this.$route.name === "TeacherSatAddCourse" ||
        this.$route.name === "TeacherEditCourse"
      );
    },
    isCompany() {
      return (
        this.$route.name === "CompanySatAddCourse" ||
        this.$route.name === "CompanyEditCourse"
      );
    },
    companyId() {
      return this.getProfile.company_id;
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    myTitle() {
      return "Edit Roster";
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    type() {
      let type = "";
      if (this.$route.name === "SatAddPractice") {
        type = "practice";
      } else if (this.$route.name === "SatAddComposePractice") {
        type = "composePractice";
      }
      return type;
    },
    ...mapState("user", ["token", "lang"]),
    isLogin() {
      return this.token !== "";
    },
    theme() {
      return Theme.name;
    },
    classId() {
      return this.$route.params.id;
    },
    isEdit() {
      return this.$route.params.id > 0;
    },
    classExamsIDArr() {
      let arr = [];
      this.classExams.forEach(exam => {
        if (exam.exam) {
          arr.push(exam.exam.id);
        }
      });
      return arr;
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      this.showSessionClass = false;
      await this.getClass(this.$route.params.id);
    }
    // await this.getComposePractices();
    // await this.getAdaptivePractices();
    // await this.getModules();
    // await this.getDrills();
    // this.searchUsers();
    // this.searchTeachers();
    if (this.isTeacher && !this.isEdit) {
      if (this.teacher_users.length === 0) {
        this.teacher_users.push(this.getProfile);
      }
    }
    this.showSessionClass = true;
  },

  methods: {
    async getComposePractices() {
      const res = await SAT.getComposePractices({
        is_new: 1,
        page: 1,
        is_public: 1,
        per_page: 100
      });
      this.composePractices = res.compose_practice.data;
    },
    async getAdaptivePractices() {
      const res = await SAT.getAdaptivePractices({
        is_new: 1,
        page: 1,
        is_public: 1,
        per_page: 100
      });
      this.adaptivePractices = res.adaptive_practices.data;
    },
    async getModules() {
      const res = await SAT.getClassPractices(this.$route.params.id, {
        categories: [13, 14]
      });
      this.modules = res.exams;
    },
    async getDrills() {
      const res = await SAT.getClassPractices(this.$route.params.id, {
        categories: [11, 12]
      });
      this.drills = res.exams;
    },
    addStudent() {
      let selectedStudent = this.students[this.selectedStudent];
      let isIn = false;
      this.student_users.forEach(student => {
        if (selectedStudent.id === student.id) {
          isIn = true;
        }
      });
      if (isIn) {
        this.$message({
          message: "Cannot be added repeatedly!",
          type: "warning"
        });
      } else {
        this.student_users.push(selectedStudent);
        this.showStudents = false;
      }
    },
    removeStudent(index) {
      console.log(index);
      this.student_users.splice(index, 1);
    },
    addTeacher() {
      let selectedTeacher = this.teachers[this.selectedTeacher];
      let isIn = false;
      this.teacher_users.forEach(student => {
        if (selectedTeacher.id === student.id) {
          isIn = true;
        }
      });
      if (isIn) {
        this.$message({
          message: "Cannot be added repeatedly!",
          type: "warning"
        });
      } else {
        this.teacher_users.push(selectedTeacher);
        this.showTeachers = false;
      }
    },
    showTeachersWindow() {
      this.showTeachers = true;
      this.selectedTeacher = null;
    },
    showStudentWindow() {
      this.showStudents = true;
      this.selectedStudent = null;
    },
    removeTeacher(index) {
      this.teacher_users.splice(index, 1);
    },
    addNewExam(type) {
      this.classExams.push({
        type: type,
        exam_id: null,
        which_week: this.classExams.length,
        exam_date: null,
        hide_exp: 0
      });
    },
    removeExam(index) {
      this.classExams.splice(index, 1);
    },
    async searchUsers(query) {
      if (this.isAdmin) {
        this.searchAdminUsers(query);
      }
      if (this.isCompany) {
        this.searchCompanyUsers(query);
      }
    },
    async searchTeachers(query) {
      if (this.isAdmin) {
        this.searchAdminTeachers(query);
      }
      if (this.isCompany) {
        this.searchCompanyTeachers(query);
      }
    },
    async searchAdminUsers(query) {
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        per_page: 50,
        sort: "name",
        page: this.page
      });
      this.students = students.users.data;
    },

    async searchCompanyUsers(query) {
      const students = await Company.getUsers({
        keyword: query !== "" ? query : null,
        per_page: 50,
        sort: "name",
        page: this.page
      });
      this.students = students.users.data;
    },
    async searchAdminTeachers(query) {
      const students = await SAT.getStudents({
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page,
        sort: "name",
        roles: "teacher"
      });
      this.teachers = students.users.data;
    },
    async searchCompanyTeachers(query) {
      const res = await Company.listCompanyTeachers(this.companyId, {
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page
      });
      this.teachers = res.users.data;
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.sessionClass["name"] = res["name"];
      this.sessionClass["description"] = res["description"];
      // this.sessionClass["is_online"] = res["is_online"];
      this.sessionClass["started_time"] = res["started_time"];
      this.sessionClass["ended_time"] = res["ended_time"];
      this.classDate = [
        res["started_at"] ? res["started_at"] : "",
        res["ended_at"] ? res["ended_at"] : ""
      ];
      // let exams = res["session_class_exams"];
      // exams.forEach(exam => {
      //   if (
      //     exam.exam.test_type === "Modules\\Practice\\Entities\\ComposePractice"
      //   ) {
      //     exam["type"] = "Linear";
      //   } else if (exam.exam.test_type === "App\\Entities\\AdaptivePractice") {
      //     exam["type"] = "Adaptive";
      //   } else if (
      //     exam.exam.test_type === "Modules\\Practice\\Entities\\Practice"
      //   ) {
      //     if (exam.exam.category == 11 || exam.exam.category == 12) {
      //       exam["type"] = "Drills";
      //     } else {
      //       exam["type"] = "Modules";
      //     }
      //   }
      // });
      // this.classExams = exams;
      // this.student_users = res["student_users"];
      // this.old_student_users = JSON.parse(JSON.stringify(res["student_users"]));
      this.teacher_users = res["users"];
      this.student_users = res["users"];
      // this.old_teacher_users = JSON.parse(JSON.stringify(res["teacher_users"]));
    },
    createNew(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.sessionClass.started_at = this.classDate[0];
          this.sessionClass.ended_at = this.classDate[1];
          let class_id = null;
          if (this.isEdit) {
            class_id = this.classId;
            await Class.updateClass(this.classId, this.sessionClass);
          } else {
            const res = await Class.createClass(this.sessionClass);
            // class_id = res.session_class.id;
          }
          // if (class_id && this.student_users && this.student_users.length > 0) {
          //   let remove_student_user_ids = [];
          //   this.old_student_users.forEach(old_student => {
          //     let inArr = false;
          //     this.student_users.forEach(student => {
          //       if (old_student.id === student.id) {
          //         inArr = true;
          //       }
          //     });
          //     if (!inArr) {
          //       remove_student_user_ids.push(old_student.id);
          //     }
          //   });
          //   if (remove_student_user_ids.length > 0) {
          //     await Class.removeStudents({
          //       session_class_id: class_id,
          //       student_user_ids: remove_student_user_ids
          //     });
          //   }

          //   let student_user_ids = [];
          //   this.student_users.forEach(student => {
          //     student_user_ids.push(student.id);
          //   });
          //   await Class.addStudentsToClass({
          //     session_class_id: class_id,
          //     student_user_ids: student_user_ids
          //   });
          // }
          // if (class_id && this.teacher_users && this.teacher_users.length > 0) {
          //   let remove_teacher_user_ids = [];
          //   this.old_teacher_users.forEach(old_teacher => {
          //     let inArr = false;
          //     this.teacher_users.forEach(teacher => {
          //       if (old_teacher.id === teacher.id) {
          //         inArr = true;
          //       }
          //     });
          //     if (!inArr) {
          //       remove_teacher_user_ids.push(old_teacher.id);
          //     }
          //   });
          //   if (remove_teacher_user_ids.length > 0) {
          //     await Class.removeTeachers({
          //       session_class_id: class_id,
          //       teacher_user_ids: remove_teacher_user_ids
          //     });
          //   }

          //   let user_ids = [];
          //   this.teacher_users.forEach(student => {
          //     user_ids.push(student.id);
          //   });
          //   await Class.addTeachersToClass({
          //     session_class_id: class_id,
          //     teacher_user_ids: user_ids
          //   });
          // }
          // if (this.classExams && this.classExams.length > 0) {
          //   let saveExams = [];
          //   this.classExams.forEach((exam, index) => {
          //     if (exam.exam_id > 0) {
          //       exam.which_week = index;
          //       saveExams.push(exam);
          //     }
          //   });
          //   if (saveExams.length > 0) {
          //     await Class.updateClassExams({
          //       session_class_id: class_id,
          //       session_class_exams: saveExams
          //     });
          //   }
          // }
          this.$message({
            message: "Success!",
            type: "success"
          });
          this.$router.go(-1);
        } else {
          // if (!this.teacher_users || this.teacher_users.length < 1) {
          //   this.$message.error("Choose at least one teacher!");
          // } else {
          //   console.log("error submit!!");
          // }
          // return false;
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .select-popper {
  z-index: 9999 !important;
  top: auto !important;
  left: auto !important;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
</style>
